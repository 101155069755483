<template>
	<div>
		<div class="chat-main-header">
			<div class="headchat" v-if="contactUiFlag.selectedContactName">
				<div class="pull-left left-side">
					<div class="open-panel-mobile pull-left pl-0" @click="openPanel()">
						<i class="ti-angle-left"></i>
					</div>
					<a class="link-user">
						<div class="wrapp-img" @click="openProfile" data-toggle="modal">
							<span v-if="contactUiFlag.selectedProfilePicUrl">
								<img :src="contactUiFlag.selectedProfilePicUrl" alt="user-img" class="img-circle" @error="handleImageError($event, 'contact')" />
							</span>
							<span
								v-if="!contactUiFlag.selectedProfilePicUrl && contactUiFlag.selectedContactName != ''">
								<div class="initials" v-if="contactUiFlag.selectedContactName.includes('+')">
									<i class="mdi mdi-account"></i>
								</div>
								<div class="initials" v-if="!contactUiFlag.selectedContactName.includes('+')">
									{{ $helpers.changeInitialName(contactUiFlag.selectedContactName) }}
								</div>
							</span>
						</div>
						<div class="chat-header">
							<span class="uname">
								{{ contactUiFlag.selectedContactName }}
							</span>
							<small title="Afiliator" v-if="contactUiFlag.currentAffiliator" class="text-affiliator ml-2 badge badge-light badge-pill">
								<i class="mdi mdi-link-variant "></i> {{ contactUiFlag.currentAffiliator.name }}
							</small>
							<br>
							<div class="wrapp-tag">
								<span v-if="contactUiFlag.selectedContactRole" class="badge badge-pill badge-success text-white mr-1">
									<v-icon style="font-size:10px !important" class="text-white" left>
										mdi-account
									</v-icon>
									{{ contactUiFlag.selectedContactRole }}
								</span>
								<span v-for="(label, index) in contactUiFlag.labels" :key="index"
									class="mr-1 badge badge-pill badge-secondary text-white">
									<span>
										<v-icon style="font-size:10px !important" class="text-white" left>
											mdi-tag
										</v-icon>
										{{ label }}
									</span>
									<v-icon style="font-size:10px !important" class="text-white" @click="deleteLabel(label)" right>
										mdi-close
									</v-icon>
								</span>
							</div>
						</div>
					</a>
				</div>
				<div class="pull-right right-side">
					<div class="desktop-only" style="margin-left:8px; float:right">
						<a v-if="contactUiFlag.selectedIsUnknonwnContact === true" class="text-dark pull-right ml-2"  title="Tambah Kontak"
							style="font-size:20px; line-height:2;" @click="showAddContactModal" href="#">
							<i class="mdi mdi-account-plus"></i>
						</a>
						<a title="Tandai Kontak" class="text-warning pull-right ml-2" style="font-size:20px; line-height:2;" @click="updateStars">
							<i class="mdi" :class="{'mdi-star' : contactUiFlag.selectedStars, 'mdi-star-outline' : !contactUiFlag.selectedStars}"></i>
						</a>
						<a v-if="page == 'my-conversation'" title="Ubah Label" class="text-dark pull-right" style="font-size:20px; line-height:2;" 
							@click="showLabelLookUp">
							<i class="mdi mdi-tag-multiple"></i>
						</a>
					</div>
					<b-dropdown class="drop-chat-room pull-right mobile-only" id="dropdown-right" right variant="link" toggle-class="text-decoration-none" no-caret>
						<template #button-content>
							<span>
								<i class="mdi mdi-dots-vertical" style="font-size:16px"></i>
							</span>
						</template>
						<b-dropdown-item v-if="contactUiFlag.selectedIsUnknonwnContact === true" @click="showAddContactModal" href="#">
							<span class="mr-1">
								<i class="mdi mdi-account-plus"></i>
							</span>
							Tambah Kontak
						</b-dropdown-item>
						<b-dropdown-item @click="updateStars">
							<span class="mr-1">
								<i class="mdi" :class="{'mdi-star' : contactUiFlag.selectedStars, 'mdi-star-outline' : !contactUiFlag.selectedStars}"></i>
							</span>
							{{ contactUiFlag.selectedStars ? "Batal " : ""}}Tandai Kontak
						</b-dropdown-item>
						<b-dropdown-item v-if="page == 'my-conversation'" @click="showLabelLookUp">
							<span class="mr-1">
								<i class="mdi mdi-tag-multiple"></i>
							</span>
							Ubah Label
						</b-dropdown-item>
					</b-dropdown>
					<a class="search-expand pull-right">
						<span v-if="msgUiFlag.isLoading && showConversation" style="margin-right:10px; margin-top:5px;">
							<b-spinner small variant="secondary" label="Loading..."></b-spinner>
						</span>
						<input id="msg" @keyup.enter="searchPersonalMessage" autocomplete="off" v-model="msgUiFlag.searchTerm" type="search" placeholder="Cari" />
					</a>
				</div>
			</div>
			<div class="error-message" v-if="!$helpers.isBlank(msgUiFlag.error) || !$helpers.isBlank(error) ">
				<ErrorMessage :error="msgUiFlag.error || error" />
			</div>
			<div class="lookup-label" v-if="labelUiFlag.showEditLabel && contactUiFlag.selectedContactName">
				<div>
					<v-combobox label="Ubah label" hide-selected multiple persistent-hint small-chips
						v-model="labelUiFlag.selectedLabels" 
						:items="labelUiFlag.labels"
						:search-input.sync="labelUiFlag.search">
						<template v-slot:no-data>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>
										Tidak ditemukan "<strong>{{ labelUiFlag.search }}</strong>".
										Tekan <kbd>enter / tab</kbd> untuk membuat label baru
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
						</template>
						<template v-slot:selection="{ item }">
							<div class="mt-1 mr-1 badge badge-pill badge-secondary text-white">
								<span>
									<v-icon style="font-size:10px !important" class="text-white" left>
										mdi-tag
									</v-icon>
								</span>
								<span>{{ item }}</span>
								<v-icon style="font-size:10px !important" class="text-white" @click="deleteSelectedLabel(item)" right>
									mdi-close $delete
								</v-icon>
							</div>
						</template>
						<template v-slot:item="{ item }">
							<div class="row">
								<div class="col-md-10">
									<span class="pull-left">
										{{ item }}
									</span>
								</div>
								<div class="col-md-2">
									<button class="pull-right btn btn-danger btn-sm" @click="deleteLabelApi(item)">
										Hapus
									</button>
								</div>
							</div>
						</template>
					</v-combobox>
				</div>
				<div>
					<button class="btn btn-primary btn-sm mr-2" @click="editLabel">
						<i v-if="labelUiFlag.isLoading" class="fa fa-circle-o-notch fa-spin"></i>
						&nbsp;
						Simpan
					</button>
					<button class="btn btn-secondary btn-sm" @click="cancelEditLabel">
						Batal
					</button>
				</div>
			</div>
		</div>
		<div class="clearfix"></div>

		<AddToContact 
			:addedContactId="contactUiFlag.selectedContactId"
            :sessionId="contactUiFlag.selectedSessionId" 
			:phoneNumber="contactUiFlag.selectedContactId"
            @save-callback="saveContactCallback" />

		<AccountDetail ref="accountDetail" 
			:contactId="contactUiFlag.selectedContactId"
            :parentId="contactUiFlag.selectedParentAccountId" :parentName="contactUiFlag.selectedParentAccountName"
            :unknonwnContact="contactUiFlag.selectedIsUnknonwnContact"
            :contactNumber="contactUiFlag.selectedContactPrimaryNumber"
            :contactName="contactUiFlag.selectedContactName"
            @copy-info-message-template="copyStringCallback" />

	</div>
</template>
<script>
import axios from "axios";
import Toast from '../plugins/vuenotification'

import { mapState, mapActions } from 'vuex'

import IMAGE_ACC from '../../public/images/dummy.jpg'
import IMAGE_MESSAGE from '../../public/images/image-not-found.png'

import AccountDetail from './AccountDetail.vue';
import AddToContact from './AddToContact.vue';
import ErrorMessage from './ErrorMessage.vue';

const $ = require("jquery")

export default {

	props: ['page', 'contactUiFlag', 'msgUiFlag', "showConversation"],

	components: {
		AccountDetail,
		AddToContact,
		ErrorMessage
	},

	data: function() {
		return {
			error: Object(),

			labelUiFlag: {
				showEditLabel: false,
				selectedLabels: Array(),
				labels: Array(),
				search: String(),
				tempSelectedLabels: Array(),
				tempLabels: Array(),
				isLoading: false,
			},
		}
	},

	computed: {
		...mapState({
			labels: state => state.select.labels
		}),
	},

	methods: {
		...mapActions({
			actGetLabel: 'select/actGetLabel'
		}),

		openPanel: function() {
			const panel = $(".open-panel")
			panel.click()
		},

		openProfile: async function (event) {
			event.preventDefault();
			if (!this.contactUiFlag.selectedIsUnknonwnContact) this.$refs.accountDetail.openProfile()
		},

		showAddContactModal: function (event) {
			event.preventDefault()
			this.$bvModal.show('add-to-contact-modal')
		},

		showLabelLookUp: function (event) {
			event.preventDefault()

			this.labelUiFlag.showEditLabel = !this.labelUiFlag.showEditLabel
			this.labelUiFlag.selectedLabels = this.contactUiFlag.labels
		},

		saveContactCallback: function (isSuccess, newContactId, data) {
			this.$emit('saveContactCallback', isSuccess, newContactId, data)	
		},

		searchPersonalMessage: function() {
			this.$emit('searchPersonalMessage')	
			this.openPanel()
		},

		updateStars: async function() {
			try {
				const data = { stars: !this.contactUiFlag.selectedStars }

				await axios.put(process.env.VUE_APP_API_URL + '/contact/star/' + this.contactUiFlag.selectedContactId, data)

				document.getElementById("inputor").focus();
			} catch (error) {
				this.error = this.$helpers.createError(error)
			}
		},

		editLabel: async function () {
			try {
				this.labelUiFlag.isLoading = true

				const postData = { labels: this.labelUiFlag.selectedLabels }
				
				await axios.put(process.env.VUE_APP_API_URL + '/contact/label/' + this.contactUiFlag.selectedContactId, postData)

				this.$emit('editLabel', this.labelUiFlag.selectedLabels)
				
				const difference = this.labelUiFlag.selectedLabels.filter(x => this.labelUiFlag.tempSelectedLabels.indexOf(x) === -1);

				this.labelUiFlag.labels = [...this.labelUiFlag.labels, ...difference]
				this.labelUiFlag.isLoading = false
				this.labelUiFlag.showEditLabel = false

				Toast.showToast("success", "Label berhasil disimpan")
			} catch (error) {
				this.labelUiFlag.isLoading = false
				this.error = this.$helpers.createError(error)
			}
		},

		cancelEditLabel: function () {
			this.labelUiFlag.showEditLabel = false
			this.labelUiFlag.labels = this.labelUiFlag.tempLabels
			this.labelUiFlag.selectedLabels = this.contactUiFlag.labels
		},

		deleteSelectedLabel: function (label) {
			this.labelUiFlag.selectedLabels = this.labelUiFlag.selectedLabels.filter(e => e != label)
		},

		deleteLabel: async function (label) {
			try {
				const deletedLabel = this.contactUiFlag.labels.filter(e => e != label)
				const postData = { labels: deletedLabel }

				await axios.put(process.env.VUE_APP_API_URL + '/contact/label/' + this.contactUiFlag.selectedContactId, postData)

				this.$emit('deleteLabel', deletedLabel)

				Toast.showToast("success", "Label berhasil dihapus")
			} catch (error) {
				this.error = this.$helpers.createError(error)
			}
		},

		deleteLabelApi: async function (label) {
			try {
				setTimeout(() => {
					this.labelUiFlag.selectedLabels = this.labelUiFlag.selectedLabels.filter(e => e != label)
				}, 50)

				await axios.delete(process.env.VUE_APP_API_URL + '/label/' + label)

				Toast.showToast("success", "Label berhasil dihapus")
			} catch (error) {
				this.error = this.$helpers.createError(error)
				Toast.showToast("error", error.response.data.message)
			}
		},

		copyStringCallback: function (str) {
			this.$emit('copyStringCallback', str)
		},

		handleImageError: function (event, type) {
			if (type == 'contact') event.target.src = IMAGE_ACC;
			if (type == 'message') event.target.src = IMAGE_MESSAGE;
		},
	},

	async mounted() {
		await this.actGetLabel()
		this.labelUiFlag.labels = this.labels
		this.labelUiFlag.tempLabels = this.labels
	}
}
</script>