<template>
    <div>
        <b-modal class="modal-lg" id="profile-modal" title="Profile" @hidden="resetModal()">
            <ErrorMessage :error="error" />
            <div class="row m-b-20">
                <div class="col-sm-12 col-md-12 profile-detail">
                    <div class="pull-left mr-4">
                        <img class="img-fluid profile-pic" :src="data.displayImage" alt="user"
                            @error="handleImageError($event)">
                    </div>
                    <div class="pull-left">
                        <div>
                         <h4 class="mt-3 pull-left mr-2">{{ data.name }}{{ data.affiliator ? " (Affiliator)" : "" }}</h4> 
                            <span class="badge badge-pill badge-success" title="Status Akun" v-if="data.accountStatus">
                                <span style="color:white">Aktif</span>
                            </span>
                            <span class="badge badge-pill badge-danger" title="Status Akun" v-else>
                                <span style="color:white">Nonaktif</span>
                            </span>
                        </div>
                        <div class="clearfix"></div>
                        <div>
                            <i class="mdi mdi-phone"></i>
                            {{ data.primaryNumber }}
                            <i class="ml-2 mdi mdi-email"></i>
                            {{ data.primaryEmail }}
                              <i class="ml-2 mdi mdi-account"></i>
                            {{ data.customerRole }}
                        </div>
                        <div class="clearfix"></div>
                    </div>
                </div>
            </div>
            <div>
                <div v-if="parentName">
                    <h6>Akun Utama</h6>
                    <span text-muted> {{ parentName }}</span>
                    <br />
                    <br />
                </div>
                <div v-if="subAccountList.length > 1">
                    <h6>Sub Akun</h6>
                    <div class="text-muted" v-for="(item, index) in subAccountList" :key="index">
                        <span>{{item.accountId}} - {{ item.accountName }}</span>
                    </div>
                    <br />
                </div>
                <div class="row" v-if="data.primaryAddress">
                    <div class="col-12">
                        <h6>Alamat</h6>
                        <span class="text-muted">{{ data.primaryAddress }}</span>
                    </div>
                </div>
                <div class="m-t-20">
                    <div class="m-t-30">
                        <b-tabs content-class="mt-3" v-model="tab">
                            <b-tab title="Alamat">
                                <div class="alert alert-secondary" role="alert">
                                    <div class="row">
                                        <div class="col col-md-8">
                                            Cari tahu ongkos kirim dengan alamat yang berbeda
                                        </div>
                                        <div class="col text-right col-md-4">
                                            <button class="btn btn-dark" @click="showCourierModalManual">
                                                Cek Ongkir
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div class="row">
                                        <div class="col col-9">
                                            <v-text-field v-model="streetTerm"
                                                label="Cari Alamat"
                                            ></v-text-field>
                                        </div>
                                        <div class="col col-3 text-right">
                                            <v-btn class="btn mt-3" 
                                                @click="number = 1, searchCustAddress(streetTerm)"
                                                :disabled="isLoading"
                                            >
                                                Cari
                                            </v-btn>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="isLoading" class="text-center mt-2">
                                    <b-spinner small variant="secondary" label="Loading..."></b-spinner>
                                </div>
                                <div class="address-list">
                                    <div class="card m-b-10" v-for="item in addresses" :key="item.addresses.id">
                                        <div class="card-body p-10">
                                            <div class="row">
                                                <div class="col col-md-9">
                                                    <h5 class="card-title">
                                                        {{ item.addresses.name }}
                                                        <span class="badge badge-pill badge-success text-white"
                                                            v-if="item.addresses.primary">
                                                            Utama
                                                        </span>
                                                       
                                                    </h5>
                                                    <div class="card-text text-justify">
                                                        <div class="address-street">{{ item.addresses.street ? item.addresses.street + ',' : '' }}</div>
                                                        {{ item.addresses.district ? item.addresses.district + ',' : '' }}
                                                        {{ item.addresses.city ? item.addresses.city + ',' : '' }}
                                                        {{ item.addresses.province ? item.addresses.province + '.' : '' }}
                                                        <br>
                                                        <span>{{ item.addresses.postalCode }}</span>
                                                    </div>
                                                </div>
                                                <div class="col col-md-3 text-right list-icon-address">
                                                     <a class="rounded-pill mr-2" icon @click="openDetailCommerce(item)" title="Detail Address Di Commerce">
                                                            <v-icon class="">
                                                                mdi-open-in-new
                                                            </v-icon>
                                                        </a>
                                                    <a class="rounded-pill mr-2"
                                                        @click="showAddressModal(item.addresses)" title="Detail Alamat">
                                                        <v-icon class="">mdi-map-marker</v-icon>
                                                    </a>
                                                    <a class="rounded-pill"
                                                        @click="showCourierModal(item.addresses)"
                                                        title="Estimasi Ongkos Kirim">
                                                        <v-icon class="">mdi-truck</v-icon>
                                                    </a>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-2" style="color: black !important;" v-if="addresses.length > 0">
                                    <div class="col pagination-address">
                                        <v-pagination color circle
                                            v-model="number" 
                                            :length="totalPages" 
                                            :total-visible="5" 
                                            prev-icon="mdi-menu-left"
                                            next-icon="mdi-menu-right"
                                        ></v-pagination>
                                    </div>
                                </div>
                            </b-tab>
                            <b-tab title="Riwayat Transaksi">
                                <div v-if="lastOrder.length < 1 && !isLoadingOrder">
                                    <span>Belum ada transaksi</span>
                                </div>
                                <div v-if="isLoadingOrder" class="text-center mt-2">
                                    <b-spinner small variant="secondary" label="Loading..."></b-spinner>
                                </div>
                                <div class="row mt-2">
                                    <div class="card card-last-order" v-for="(item) in lastOrder" :key="item.id">
                                        <div class="wrapp-img-last-order">
                                            <span class="" v-if="item.displayImage">
                                                <img class="card-img-top" :src="item.displayImage.src" alt="product">
                                            </span>
                                        </div>
                                        <div class="card-body">
                                            <p class="card-text">
                                                {{ item.product.localSku }}
                                                <br />
                                                <span v-if="item.properties != null">
                                                    <span v-for="(e) in item.properties" :key="e.formalId">
                                                        {{ e.name }}
                                                    </span>
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </b-tab>
                            <b-tab title="Grup">
                                <v-data-table :headers="headersGroup" :items="dataGroup" :items-per-page="10"
                                    class="elevation-1">
                                    <template v-slot:item.status="{ item }">
                                        <span>{{ item.status == 'ACTIVE' ? 'Aktif' : item.status == 'INACTIVE' ? 'Non Aktif' : '' }}</span>
                                    </template>
                                    <template v-slot:item.action="{ item }">
                                        <router-link
                                            :to="{ name: 'group' , params: {id: encodeURIComponent(item._id)} }"
                                            target="_blank">
                                            <v-icon title="Detail Group" class="mr-2 accent-4">mdi-arrow-right</v-icon>
                                        </router-link>
                                    </template>
                                </v-data-table>
                            </b-tab>
                            <b-tab title="Wishlist">
                                <div class="container">
                                    <div class="row">
                                        <div class="col col-6">
                                            <v-text-field v-model="wishlistTerm" label="Nama / Kode Produk" />
                                        </div>
                                        <div class="col col-4">
                                            <v-select v-model="selectedStock"
                                                :items="stockItem" 
                                                item-value="value"
                                                item-text="id"
                                                label="Stok"
                                            ></v-select>
                                        </div>
                                        <div class="col col-2 text-right">
                                            <v-btn class="btn mt-3" 
                                                @click="number = 1, searchCustWishlists()"
                                                :disabled="isLoading">
                                                Cari
                                            </v-btn>
                                        </div>
                                    </div>
                                    <div v-if="isLoading" class="text-center mt-2 mb-3">
                                        <b-spinner small variant="secondary" label="Loading..."></b-spinner>
                                    </div>
                                    <div v-for="(wishlist, index) in wishlists" :key="wishlist.id" class="card p-2 mt-0 mb-2">
                                        <div class="row align-items-center">
                                            <div class="col col-md-2">
                                                <img :src="wishlist.itemImage.uri" :alt="wishlist.item.localSku" width="100%" />
                                            </div>
                                            <div class="col col-md-5">
                                                <div>
                                                    <b>{{ wishlist.item.name }}</b>
                                                    <div class="mb-1 mt-1 text-secondary">
                                                        {{ wishlist.item.localSku }}
                                                    </div>
                                                </div>
                                                <div>
                                                    <s v-if="wishlist.effectivePrice != wishlist.item.price">
                                                        Rp {{ formatCurrency(wishlist.item.price) }}
                                                    </s>
                                                </div>
                                                <div>
                                                    <b>
                                                        Rp {{ formatCurrency(wishlist.effectivePrice) }}
                                                    </b>
                                                </div>
                                                <div class="mt-2">
                                                    <span v-if="wishlist.effectivePrice != wishlist.item.price" class="badge badge-success mr-2">
                                                        {{ ((wishlist.item.price - wishlist.effectivePrice) / wishlist.item.price) * 100 }}%
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="col col-md-2">
                                                <div v-if="wishlist.itemVariant">
                                                    <div class="mb-2">
                                                        Varian :
                                                    </div>
                                                    <span v-if="wishlist.itemVariant.map && wishlist.itemVariant.map.base_color" 
                                                        class="box-color box mr-2 pull-left" 
                                                        :style="{ 
                                                            'background-color': wishlist.itemVariant.optionProperties.base_color.displayValue.replace(' ', '') 
                                                        }"
                                                    ></span>
                                                    <span v-if="wishlist.itemVariant.map && wishlist.itemVariant.map.base_size">
                                                        {{ wishlist.itemVariant.optionProperties.base_size.displayValue }}
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="col col-md-3 text-right">
                                                <div v-if="wishlist.isLoading" class="text-center">
                                                    <b-spinner small variant="secondary" label="Loading..."></b-spinner>
                                                </div>
                                                <div v-else>
                                                    <v-btn icon class="text-warning" title="Update Notifikasi" 
                                                        @click="setNotifWishlists(index, wishlist.id, wishlist.pendingNotification ? true : false)">
                                                        <v-icon>
                                                            {{ wishlist.pendingNotification ? 'mdi-bell' : 'mdi-bell-off' }}
                                                        </v-icon>
                                                    </v-btn>
                                                    <v-btn icon class="text-danger" title="Hapus" @click="deleteCustWishlists(index, wishlist.id)">
                                                        <v-icon>mdi-delete</v-icon>
                                                    </v-btn>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-2" style="color: black !important;" v-if="wishlists.length > 0">
                                        <div class="col col-12 pagination-address">
                                            <v-pagination color circle
                                                v-model="numberWishlist" 
                                                :length="totalPagesWishlist" 
                                                :total-visible="5" 
                                                prev-icon="mdi-menu-left"
                                                next-icon="mdi-menu-right"
                                            ></v-pagination>
                                        </div>
                                    </div>
                                </div>
                            </b-tab>
                        </b-tabs>
                    </div>
                </div>
                <br />
            </div>
        </b-modal>
        <b-modal id="address-detail-modal" title="Detail Alamat" hide-footer @hidden="hideAddressModal">
            <form>
                <div class="row">
                    <label for="staticEmail" class="col-sm-3 col-form-label">Nama</label>
                    <div class="col-sm-9">
                        {{ selectedAddress.name }}
                    </div>
                </div>
                <div class="row">
                    <label for="inputPassword" class="col-sm-3 col-form-label">No HP</label>
                    <div class="col-sm-9">
                        {{ selectedAddress.primaryMobile }}
                    </div>
                </div>
                <div class="row">
                    <label for="inputPassword" class="col-sm-3 col-form-label">Email</label>
                    <div class="col-sm-9">
                        {{ selectedAddress.primaryEmail }}
                    </div>
                </div>
                <div class="row">
                    <label for="inputPassword" class="col-sm-3 col-form-label">Alamat</label>
                    <div class="col-sm-9">
                        {{ selectedAddress.street }}
                    </div>
                </div>
                <div class="row">
                    <label for="inputPassword" class="col-sm-3 col-form-label">Provinsi</label>
                    <div class="col-sm-9">
                        {{ selectedAddress.province }}
                    </div>
                </div>
                <div class="row">
                    <label for="inputPassword" class="col-sm-3 col-form-label">Kota</label>
                    <div class="col-sm-9">
                        {{ selectedAddress.city }}.
                    </div>
                </div>
                <div class="row">
                    <label for="inputPassword" class="col-sm-3 col-form-label">Kecamatan</label>
                    <div class="col-sm-9">
                        {{ selectedAddress.district }}
                    </div>
                </div>
                <div class="row">
                    <label for="inputPassword" class="col-sm-3 col-form-label">Kode Pos</label>
                    <div class="col-sm-9">
                        {{ selectedAddress.postalCode }}
                    </div>
                </div>
                <!-- <div class="row mb-2 text-right">
                    <div class="col">
                        <a href="#" class="btn btn-primary" title="Estimasi Ongkos Kirim"
                            @click="showCourierModal(selectedAddress)">
                            Selanjutnya
                        </a>
                    </div>
                </div> -->
            </form>
        </b-modal>
        <b-modal id="courier-cost-modal" hide-footer title="Estimasi Ongkos Kirim" @hidden="hideCourierModal">
            <form>
                <!-- <div class="row">
                    <label for="staticEmail" class="col-sm-1 col-form-label">
                        <v-icon>mdi-map-marker</v-icon>
                    </label>
                    <div class="col-sm-10">
                        <b>Alamat Asal</b>
                        <br>
                        <div class="m-t-10"><b>Lorem Ipsum</b></div>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua</p>
                    </div>
                </div> -->
                <div class="row">
                    <label for="staticEmail" class="col-sm-1 col-form-label">
                        <v-icon>mdi-map-marker</v-icon>
                    </label>
                    <div class="col-sm-10">
                        <b>Alamat Tujuan</b>
                        <br>
                        <div class="m-t-10"><b>{{ selectedAddress.name }}</b></div>
                        <p>
                            {{ selectedAddress.street ? selectedAddress.street + ',' : '' }}
                            {{ selectedAddress.district ? selectedAddress.district + ',' : '' }}
                            {{ selectedAddress.city ? selectedAddress.city + ',' : '' }}
                            {{ selectedAddress.province ? selectedAddress.province + '. ' : '' }}
                            {{ selectedAddress.postalCode ? selectedAddress.postalCode : '' }}
                        </p>
                    </div>
                </div>
                <div class="row">
                    <label for="staticEmail" class="col-sm-1 col-form-label">
                        <v-icon>mdi-weight-kilogram</v-icon>
                    </label>
                    <div class="col-sm-10 form-inline">
                        <div class="form-group">
                            <input type="number" class="mr-2 form-control" v-model="weight">
                        </div>

                    </div>
                </div>
                 <div class="row">
                     <div class="col-sm-12 text-right">
                        <a class="btn btn-primary" @click="getOngkir(selectedAddress)">Cek</a>
                     </div>
                 </div>

                <div class="clearfix"></div>
                <div v-if="isLoadingCourier" class="text-center mt-2">
                    <b-spinner small variant="secondary" label="Loading..."></b-spinner>
                </div>
                <div class="row" v-if="!isLoadingCourier && (pricelistCourier.selectedFast.length > 0 || pricelistCourier.selectedRegular.length > 0)">
                    <div class="col">
                        <v-btn class="btn btn-primary" @click="quickSend(null, 'auto', true, $event)">
                            Kirim
                        </v-btn>
                    </div>
                </div>
                <div class="row"
                    v-if="!isLoadingCourier && (pricelistCourier.fast.length > 0 || pricelistCourier.regular.length > 0)">

                    <div class="col-sm-12">
                        <div class="m-t-20" v-if="pricelistCourier.fast.length > 0"><b>Fast (1-2 Hari)</b></div>
                        <div class="m-t-10" v-if="pricelistCourier.fast.length > 0">
                            <table class="table table-striped">
                                <tbody>
                                    <tr v-for="price in pricelistCourier.fast" :key="price.name">
                                        <th scope="row">
                                            <input type="checkbox" :value="price" v-model="pricelistCourier.selectedFast" />
                                        </th>
                                        <td>
                                            {{ price.name }}
                                            <br>
                                            <span class="text-muted">Estimasi tiba {{ setDate(price.minDeliveryTime) }}
                                                - {{ setDate(price.maxDeliveryTime) }}</span></td>
                                        <td>
                                            Rp {{ formatCurrency(price.cost) }}
                                        </td>
                                         <td class="text-right">
                                            <a href="#" class="rounded-pill btn-sm btn btn-primary text-light"
                                                title="Kirim" @click="quickSend(price, 'auto', false, $event)">
                                                <v-icon class="text-light">mdi-send</v-icon>
                                            </a>
                                         </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="m-t-20" v-if="pricelistCourier.regular.length > 0"><b>Regular (3-5 Hari)</b></div>
                        <div class="m-t-10" v-if="pricelistCourier.regular.length > 0">
                            <table class="table table-striped">

                                <tbody>
                                    <tr v-for="price in pricelistCourier.regular" :key="price.name">
                                        <th scope="row">
                                            <input type="checkbox" :value="price" v-model="pricelistCourier.selectedRegular" />
                                        </th>
                                        <td>
                                            {{ price.name }}
                                            <br>
                                            <span class="text-muted">Estimasi tiba {{ setDate(price.minDeliveryTime) }}
                                                - {{ setDate(price.maxDeliveryTime) }}</span></td>
                                        <td>
                                            Rp {{ formatCurrency(price.cost) }}
                                        </td>
                                        <td class="text-right">
                                            <a href="#" class="rounded-pill btn-sm btn btn-primary text-light"
                                                title="Kirim" @click="quickSend(price, 'auto', false, $event)">
                                                <v-icon class="text-light">mdi-send</v-icon>
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </form>
        </b-modal>
        <b-modal id="courier-cost-modal-manual" title="Cek Estimasi Ongkos Kirim" hide-footer
            @hidden="hideCourierModalManual">
            <v-form v-model="formManualOngkirValid">

                <v-row class="">
                    <v-col>
                        <v-textarea rows="2" v-model="street" label="Alamat"></v-textarea>
                    </v-col>
                </v-row>
                <v-row class="">
                    <v-col class="">
                        <v-autocomplete dense clearable :items="provinces" :loading="isLoadingProvince"
                            v-model="selectedProvince" label="Provinsi" :rules="rules.required"></v-autocomplete>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-autocomplete dense clearable :items="cities" :loading="isLoadingCity" v-model="selectedCity"
                            label="Kota" :rules="rules.required"></v-autocomplete>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-autocomplete dense clearable return-object :items="districts" :loading="isLoadingDistrict"
                            v-model="selectedDistrict" label="Kecamatan" item-text="district" :rules="rules.required">
                        </v-autocomplete>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-autocomplete dense clearable :items="selectedDistrict.postalCodes"
                            v-model="selectedPostalCode" label="Kode Pos" :rules="rules.required"></v-autocomplete>
                    </v-col>
                </v-row>
                <v-row class="mb-4">
                    <v-col>
                        <v-text-field v-model="weight" label="Berat" type="number" />
                    </v-col>
                </v-row>

                <v-btn class="btn btn-primary text-right pull-right" :disabled="!formManualOngkirValid"
                    @click="getManualOngkir">
                    Cek
                </v-btn>
            </v-form>
            <div class="clearfix"></div>
            <div v-if="isLoadingCourier" class="text-center mt-2">
                <b-spinner small variant="secondary" label="Loading..."></b-spinner>
            </div>
            <div class="row" v-if="!isLoadingCourier && (pricelistCourier.selectedFast.length > 0 || pricelistCourier.selectedRegular.length > 0)">
                <div class="col">
                    <v-btn class="btn btn-primary" @click="quickSend(null, 'manual', true, $event)">
                        Kirim
                    </v-btn>
                </div>
            </div>
            <div class="row mt-4"
                v-if="!isLoadingCourier && (pricelistCourier.fast.length > 0 || pricelistCourier.regular.length > 0)">
                <!-- <label for="staticEmail" class="col-sm-1 col-form-label">
                    <v-icon>mdi-truck</v-icon>
                </label> -->
                <div class="col-sm-12">
                    <div class="m-t-10" v-if="pricelistCourier.fast.length > 0"><b>Fast (1-2 Hari)</b></div>
                    <div class="m-t-10" v-if="pricelistCourier.fast.length > 0">
                        <table class="table table-striped">
                            <tbody>
                                <tr v-for="price in pricelistCourier.fast" :key="price.name">
                                    <th scope="row">
                                        <input type="checkbox" :value="price" v-model="pricelistCourier.selectedFast" />
                                    </th>
                                    <td>
                                        {{ price.name }}
                                        <br>
                                        <span class="text-muted">Estimasi tiba {{ setDate(price.minDeliveryTime) }} -
                                            {{ setDate(price.maxDeliveryTime) }}</span></td>
                                    <td>
                                        Rp {{ formatCurrency(price.cost) }}

                                    </td>
                                    <td class="text-right">
                                        <a href="#" class="rounded-pill btn-sm btn btn-primary text-light"
                                            title="send address" @click="quickSend(price, 'manual', false, $event)">
                                            <v-icon class="text-light">mdi-send</v-icon>
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="m-t-10" v-if="pricelistCourier.regular.length > 0"><b>Regular (3-5 Hari)</b></div>
                    <div class="m-t-10" v-if="pricelistCourier.regular.length > 0">
                        <table class="table table-striped">
                            <tbody>
                                <tr v-for="price in pricelistCourier.regular" :key="price.name">
                                    <th scope="row">
                                        <input type="checkbox" :value="price" v-model="pricelistCourier.selectedRegular" />
                                    </th>
                                    <td>
                                        {{ price.name }}
                                        <br>
                                        <span class="text-muted">Estimasi tiba {{ setDate(price.minDeliveryTime) }} -
                                            {{ setDate(price.maxDeliveryTime) }}</span></td>
                                    <td>
                                        Rp {{ formatCurrency(price.cost) }}
                                      
                                    </td>
                                    <td class="text-right">
                                          <a href="#" class="rounded-pill btn-sm btn btn-primary text-light"
                                            title="Kirim" @click="quickSend(price, 'manual', false, $event)">
                                            <v-icon class="text-light">mdi-send</v-icon>
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </b-modal>
    </div>
</template>
<script>
    import axios from 'axios'

    import { mapState } from 'vuex'

    import ErrorMessage from '../components/ErrorMessage'

    import IMAGE_ACC from '../../public/images/dummy.jpg'
    import Toast from '../plugins/vuenotification'

    const template = require('lodash.template');
    var moment = require('moment');

    moment.locale('id')

    export default {
        props: ['contactId', 'parentId', 'contactNumber', 'contactName', 'parentName'],

        components: {
            ErrorMessage
        },

        data: function () {
            return {
                weight: 1,
                tab: 0,
                number: 1,
                numberWishlist: 1,
                totalPages: 0,
                totalPagesWishlist: 0,

                selectedProvince: String(),
                selectedCity: String(),
                selectedPostalCode: String(),
                street: String(),
                streetTerm: String(),
                wishlistTerm: String(),
                selectedStock: String(),

                data: Object(),
                selectedAddress: Object(),
                selectedDistrict: Object(),
                error: Object(),

                dataGroup: Array(),
                lastOrder: Array(),
                subAccountList: Array(),
                addresses: Array(),
                provinces: Array(),
                cities: Array(),
                districts: Array(),
                wishlists: Array(),

                isLoading: false,
                isLoadingOrder: false,
                isLoadingCourier: false,
                isLoadingProvince: false,
                isLoadingCity: false,
                isLoadingDistrict: false,
                isOk: false,
                formManualOngkirValid: false,
                isOpenOngkir: false,

                headersGroup: [{
                        text: 'Nama',
                        value: 'name',
                        sortable: false
                    },
                    {
                        text: 'Status',
                        value: 'status',
                        sortable: false
                    },
                    {
                        text: 'Aksi',
                        value: 'action',
                        sortable: false
                    },
                ],

                stockItem: [
                    { id: "Semua", value: "" },
                    { id: "Tersedia", value: true },
                    { id: "Tidak Tersedia", value: false },
                ],

                pricelistCourier: {
                    fast: Array(),
                    selectedFast: Array(),
                    regular: Array(),
                    selectedRegular: Array(),
                },

                rules: {
                    required: [(v) => !!v || ""],
                    requiredObject: [(v) => Object.keys(v).length > 0 || ""],
                    requiredArray: [(v) => v.length > 0 || ""],
                },
            }
        },

        computed: {
            ...mapState({
                commerce: state => state.session.commerce
            }),
        },

        methods: {
            showAddressModal: function (item) {
                this.isOpenOngkir = true
                this.selectedAddress = item
                this.$bvModal.hide('profile-modal');
                this.$bvModal.show('address-detail-modal');
            },
            hideAddressModal: function () {
                this.isOpenOngkir = false
                this.selectedAddress = Object()
                this.$bvModal.hide('address-detail-modal');
                this.$bvModal.show('profile-modal');
            },
            showCourierModal: async function (item) {
                this.isOpenOngkir = true
                this.selectedAddress = item
                this.$bvModal.hide('profile-modal');
                this.$bvModal.show('courier-cost-modal');

                await this.getOngkir(item)
            },
            hideCourierModal: function () {
                this.isOpenOngkir = false
                this.$bvModal.hide('courier-cost-modal');
                this.$bvModal.show('profile-modal');
                this.clearForm()
            },
            showCourierModalManual: async function () {
                this.isOpenOngkir = true
                this.$bvModal.hide('profile-modal');
                this.$bvModal.show('courier-cost-modal-manual');
                await this.getProvinces()
            },
            hideCourierModalManual: function () {
                this.isOpenOngkir = false
                this.$bvModal.hide('courier-cost-modal-manual');
                this.$bvModal.show('profile-modal');
                this.clearForm()
            },
            hideProfileModal: function () {
                this.selectedAddress = Object()
                this.$bvModal.hide('profile-modal');
            },
            resetModal: async function () {
                if(!this.isOpenOngkir) {
                    this.data = Object()
                    this.lastOrder = Array()
                    this.subAccountList = Array()
                    this.error = Object()
                    this.addresses = Array()
                }
            },
            openDetailCommerce: function (item) {
                window.open(this.commerce.webUrl + '/admin/#/security-user/' + item.id + '/address');
            },
            quickSend: async function (courier, kind, isMultiple, event) {
                event.preventDefault();

                let templates = String()
                let couriers = Array()

                if(courier) courier.formatCost = this.formatCurrency(courier.cost)

                if(isMultiple) {
                    couriers = [...this.pricelistCourier.selectedFast, ...this.pricelistCourier.selectedRegular].map(e => {
                        e.formatCost = this.formatCurrency(e.cost)

                        return e
                    })
                }

                const params = {
                    address: Object(),
                    couriers,
                    courier
                }

                if (kind == 'manual') {
                    if(!isMultiple) {
                        templates = `Kami informasikan untuk Ongkos kirimnya ya kak:)\n` +
                            `\nAlamat: <%= address.street ? address.street + ', ' : ''%><%= address.district%>, <%= address.city%>, <%= address.province%>. ID <%= address.postalCode%>` +
                            `\nKurir: <%= courier.name%> - Rp <%= courier.formatCost%>`
                    } else {
                        templates = `Kami informasikan untuk Ongkos kirimnya ya kak:)\n` +
                            `\nAlamat: <%= address.street ? address.street + ', ' : ''%><%= address.district%>, <%= address.city%>, <%= address.province%>. ID <%= address.postalCode%>` +
                            `\nKurir:` + 
                            `<% couriers.forEach(cour => {%>\n<%= cour.name%> - Rp <%= cour.formatCost%><% }) %>`
                    }

                    params.address = {
                        street: this.street,
                        district: this.selectedDistrict.district,
                        city: this.selectedCity,
                        province: this.selectedProvince,
                        postalCode: this.selectedPostalCode,
                    }
                } else {
                    if(!isMultiple) {
                        templates = `Kami informasikan untuk Ongkos kirimnya ya kak:)\n` +
                            `\nKontak: <%= address.name%> - <%= address.primaryMobile%>` +
                            `\nAlamat: <%= address.street ? address.street + ', ' : ''%><%= address.district%>, <%= address.city%>, <%= address.province%>. ID <%= address.postalCode%>` +
                            `\nKurir: <%= courier.name%> - Rp <%= courier.formatCost%>`
                    } else {
                        templates = `Kami informasikan untuk Ongkos kirimnya ya kak:)\n` +
                            `\nKontak: <%= address.name%> - <%= address.primaryMobile%>` +
                            `\nAlamat: <%= address.street ? address.street + ', ' : ''%><%= address.district%>, <%= address.city%>, <%= address.province%>. ID <%= address.postalCode%>` +
                            `\nKurir:` +
                            `<% couriers.forEach(cour => {%>\n<%= cour.name%> - Rp <%= cour.formatCost%><% }) %>`
                    }

                    params.address = this.selectedAddress
                }

                const str = template(templates)(params)

                this.hideCourierModal()
                this.hideAddressModal()
                this.hideCourierModalManual()

                await new Promise(resolve => setTimeout(resolve, 300));
                this.hideProfileModal()

                this.$emit('copy-info-message-template', str)
            },
            openProfile: async function () {
                this.tab = 0
                this.$bvModal.show("profile-modal");

                try {
                    await this.getDetailAccount()

                    if (this.isOk) {
                        await this.searchCustAddress()
                        // await this.searchSalesOrder()
                        // await this.searchCustWishlists()
                    }
                } catch (error) {
                    console.log("open-profile-headchat", error);
                }
            },
            getCourierPrice: async function (config) {
                this.isLoadingCourier = true

                try {

                    const {
                        data
                    } = await axios.get(process.env.VUE_APP_API_URL + '/cmrc/shipping', config)

                    const fast = Array()
                    const regular = Array()

                    if (data) {
                        data.forEach(e => {
                            if (e.minDeliveryDay < 2 && e.maxDeliveryDay < 3) {
                                fast.push(e)
                            } else {
                                regular.push(e)
                            }
                        })
                    }

                    this.pricelistCourier = {
                        ...this.pricelistCourier,
                        fast,
                        regular
                    }

                    this.isLoadingCourier = false
                } catch (error) {
                    this.isLoadingCourier = false
                    console.log("error search courier", error);
                }

            },
            getDetailAccount: async function () {
                this.isLoading = true
                try {
                    let id = this.contactId
                    if (this.parentId) id = this.parentId

                    const {
                        data
                    } = await axios.get(process.env.VUE_APP_API_URL + '/cmrc/account/' + id)

                    const resp = await axios.get(process.env.VUE_APP_API_URL + '/contact/id/' + this.contactId);

                    this.subAccountList = resp.data.subAccountList
                    const tempGroup = Array()

                    if (resp.data.groups) {
                        resp.data.groups.forEach(e => {
                            const group = tempGroup.find(group => group._id == e._id);

                            if (tempGroup.length < 1) {
                                tempGroup.push(e)
                            } else {
                                if (!group) tempGroup.push(e)
                            }
                        })
                    }

                    this.dataGroup = tempGroup

                    if (data) {
                        this.isOk = true

                        data.accountStatus = data.accountStatus == "ACTIVE" ? true : false
                        data.primaryNumber = data.mobileNumbers.length > 0 ? data.mobileNumbers.find(e => e
                            .primary = true).phoneNumber : null
                        const address = data.addresses ? data.addresses.find(e => e.primary == true) : null
                        data.primaryAddress = String()

                        if (address) data.primaryAddress =
                            `${address.street}, ${address.district}, ${address.city}, ${address.province}, ${address.postalCode}.`

                        if (this.parentId) {
                            data.name = resp.data.name
                            data.primaryNumber = resp.data.primaryPhoneNumber
                            data.primaryEmail = resp.data.emails.length > 0 ? resp.data.emails.find(e => e
                                .primary == true).email : ''
                        }

                        data.displayImage = data.displayImage ? data.displayImage : ''
                        this.data = data
                    }
                } catch (error) {
                    this.error = this.$helpers.createError(error)
                    this.data.name = this.contactName
                    this.data.id = this.contactId
                    this.data.primaryNumber = this.contactName
                }
                this.isLoading = false
            },
            searchSalesOrder: async function () {
                this.isLoadingOrder = true

                try {
                    const res = await axios.get(process.env.VUE_APP_API_URL + '/cmrc/sales-order', {
                        params: {
                            formalId: String(),
                            dateType: 'creationTime',
                            status: String(),
                            paymentStatus: String(),
                            shipmentStatus: String(),
                            customerId: this.parentId ? this.parentId : this.contactId,
                            page: 0,
                            size: 100,
                            sortField: 'modificationTime',
                            sortOrder: 'DESCENDING'
                        }
                    })

                    let temp = res.data.content.map(data => {
                        data.creationTime = moment(data.creationTime).format('DD MMMM YYYY')
                        return data
                    })

                    const order = [...temp].slice(0, 5)

                    let detailOrders = []

                    for (let i = 0; i < order.length; i++) {
                        if (detailOrders.length <= 5) {
                            let {
                                data
                            } = await axios.get(process.env.VUE_APP_API_URL + '/cmrc/sales-order/' + order[i]
                                .formalId)
                            detailOrders = [...detailOrders, ...data.lines]
                        }

                        this.lastOrder = detailOrders.slice(0, 5)
                    }

                    this.isLoadingOrder = false
                } catch (error) {
                    this.isLoadingOrder = false
                    console.log("error search sales order profile detail", error);
                }
            },
            searchCustAddress: async function (term) {
                this.isLoading = true

                const id = this.parentId ? this.parentId : this.contactId

                try {
                    const {
                        data
                    } = await axios.get(process.env.VUE_APP_API_URL + '/cmrc/cust-address/' + id, {
                        params: {
                            term: term,
                            page: this.number - 1,
                            size: 5
                        }
                    })

                    this.addresses = data.content
                    this.number = data.number + 1
					this.totalPages = data.totalPages
                } catch (error) {
                    console.log("error search cust address", error);
                }
                this.isLoading = false
            },
            searchCustWishlists: async function () {
                this.isLoading = true

                const id = this.parentId ? this.parentId : this.contactId

                try {
                    const { data } = await axios.get(process.env.VUE_APP_API_URL + '/cmrc/wishlist/customer/' + id, {
                        headers: {
                            'Cache-Control': 'no-cache'
                        },
                        params: {
                            term: this.wishlistTerm,
                            size: 10,
                            page: this.numberWishlist - 1,
                            sort: 'creationTime,desc',
                            isQtyAvailable: this.selectedStock
                        }
                    })

                    this.wishlists = data.content.map(e => {
                        e.isLoading = false

                        return e
                    })
                    this.numberWishlist = data.number + 1
					this.totalPagesWishlist = data.totalPages
                } catch (error) {
                    if(error.response) {
                        Toast.showToast("error", error.response.data.message)
                    } else {
                        Toast.showToast("error", error.message)
                    }
                }
                this.isLoading = false
            },
            loadingWishlistItem(index) {
                this.wishlists[index].isLoading = true
            },
            setNotifWishlists: async function(index, ids, isNotification) {
                try {
                    this.wishlists[index].isLoading = true

                    const config = Object()
                    config.params = {
                        wishlistIds: ids,
                        isNotificationSent: isNotification
                    }

                    await axios.put(process.env.VUE_APP_API_URL + `/cmrc/wishlist/notif-status`, {}, config)
                    
                    this.wishlists[index].pendingNotification = !isNotification
                    this.wishlists[index].isLoading = false
                } catch (error) {
                    this.wishlists[index].isLoading = false

                    if(error.response) {
                        Toast.showToast("error", error.response.data.message)
                    } else {
                        Toast.showToast("error", error.message)
                    }
                }
            },
            deleteCustWishlists: async function (index, wishlistId) {
                this.wishlists[index].isLoading = true

                const id = this.parentId ? this.parentId : this.contactId

                try {
                    await axios.delete(process.env.VUE_APP_API_URL + '/cmrc/wishlist/customer', {
                        params: {
                            id: wishlistId,
                            personId: id
                        }
                    })

                    this.wishlists[index].isLoading = false

                    await this.searchCustWishlists()
                } catch (error) {
                    this.wishlists[index].isLoading = false

                    if(error.response) {
                        Toast.showToast("error", error.response.data.message)
                    } else {
                        Toast.showToast("error", error.message)
                    }
                }
            },
            getManualOngkir: async function () {

                const config = Object()
                config["params"] = Object()
                config["params"]["city"] = this.selectedCity
                config["params"]["district"] = this.selectedDistrict.district
                config["params"]["postalcode"] = this.selectedPostalCode
                config["params"]["weight"] = this.weight

                await this.getCourierPrice(config)
            },
            getOngkir: async function (item) {

                const config = Object()
                config["params"] = Object()
                config["params"]["userId"] = this.parentId ? this.parentId : this.contactId
                config["params"]["addressId"] = item.id
                config["params"]["weight"] = this.weight

                await this.getCourierPrice(config)
            },
            getProvinces: async function () {
                try {
                    this.isLoadingProvince = true

                    const {
                        data
                    } = await axios.get(process.env.VUE_APP_API_URL + '/cmrc/geo/provinces')

                    this.provinces = data
                    this.isLoadingProvince = false
                } catch (error) {
                    this.isLoadingProvince = false
                    console.log("error search proviences", error);
                }
            },
            getCities: async function () {
                try {
                    this.isLoadingCity = true

                    const config = Object()
                    config["params"] = Object()
                    config["params"]["province"] = this.selectedProvince

                    const {
                        data
                    } = await axios.get(process.env.VUE_APP_API_URL + '/cmrc/geo/cities', config)

                    this.cities = data
                    this.isLoadingCity = false
                } catch (error) {
                    this.isLoadingCity = false
                    console.log("error search cities", error);
                }
            },
            getDistricts: async function () {
                try {
                    this.isLoadingDistrict = true

                    const config = Object()
                    config["params"] = Object()
                    config["params"]["province"] = this.selectedProvince
                    config["params"]["city"] = this.selectedCity

                    const {
                        data
                    } = await axios.get(process.env.VUE_APP_API_URL + '/cmrc/geo/districts', config)

                    this.districts = data
                    this.isLoadingDistrict = false
                } catch (error) {
                    this.isLoadingDistrict = false
                    console.log("error search cities", error);
                }
            },
            clearForm() {
                this.selectedAddress = Object()
                this.selectedProvince = String()
                this.selectedCity = String()
                this.selectedDistrict = Object()
                this.selectedPostalCode = String()
                this.street = String()

                this.pricelistCourier = {
                    fast: Array(),
                    selectedFast: Array(),
                    regular: Array(),
                    selectedRegular: Array(),
                }

                this.weight = 1
            },
            handleImageError: function (event) {
                event.target.src = IMAGE_ACC;
            },
            formatCurrency(price) {
                let val = (price / 1).toFixed(2).replace(".", ",");
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            },
            setDate(date) {
                var dt = moment(date);

                return dt.format('MMM Do')
            }
        },

        watch: {
            async selectedProvince() {
                this.cities = Array()
                this.selectedCity = String()
                await this.getCities()
            },
            async selectedCity() {
                this.districts = Array()
                this.selectedDistrict = Object()
                await this.getDistricts()
            },
            selectedDistrict() {
                this.selectedPostalCode = String()
            },
            number() {
				this.searchCustAddress(this.streetTerm)
			},
            numberWishlist() {
				this.searchCustWishlists()
			},
            tab(val) {
                if(val == 1 && this.lastOrder.length < 1) {
                    this.searchSalesOrder()
                } else if(val == 3 && this.wishlists.length < 1) {
                    this.searchCustWishlists()
                }
            }
        },
    }
</script>